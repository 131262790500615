.verifyWrap {
  background-color: #22FFAA !important; }

.loginPage {
  background-image: none !important;
  justify-content: center !important;
  padding-left: 0 !important; }

.background_comein {
  background: #243CBB;
  height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center; }

.form_block_login {
  background: #fff;
  padding: 90px 41px 50px 41px;
  width: 425px;
  box-sizing: border-box;
  box-shadow: 0 20px 40px rgba(0,0,0,.07);
  .facebook {
    width: 100%;
    span {
      width: 100%; } }
  h1 {
    color: #3d3d3d;
    font-size: 27px;
    margin-top: 85px;
    font-weight: bold;
    text-align: center; }
  p {
    color: #666;
    font-size: 14px;
    text-align: center;
    margin-top: 10px; } }

.social_block {
  display: flex;
  i {
    color: #fff;
    font-size: 16px; } }

.login_social {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 1em; }

.social_item {
  background: linear-gradient(to right, #49c3ff 0%,#7257ff 100%);
  width: 40px;
  height: 40px;
  border-radius: 40px;
  border: none;
  margin: 0.3em;
  position: relative;
  cursor: pointer; }

.login_vk {
  &:before {
    content: '';
    width: 20px;
    height: 20px;
    color: white;
    background: url(/img/vkLogin.svg) no-repeat center center;
    background-size: 20px 20px;
    position: absolute;
    transform: translate(-50%, -50%); } }

.login_google {
  &:before {
    content: '';
    width: 15px;
    height: 15px;
    color: white;
    background: url(/img/google-logo.svg) no-repeat center center;
    background-size: 15px 15px;
    position: absolute;
    transform: translate(-50%, -50%); } }

.login_yandex {
  &:before {
    content: '';
    width: 15px;
    height: 15px;
    color: white;
    background: url(/img/yandex-logo.svg) no-repeat center center;
    background-size: 15px 15px;
    position: absolute;
    transform: translate(-50%, -50%); } }

.login_mailru {
  &:before {
    content: '';
    width: 15px;
    height: 15px;
    color: white;
    background: url(/img/mailru-logo.svg) no-repeat center center;
    background-size: 15px 15px;
    position: absolute;
    transform: translate(-50%, -50%); } }

.login_ok {
  &:before {
    content: '';
    width: 20px;
    height: 20px;
    color: white;
    background: url(/img/odnoklassniki-logo.svg) no-repeat center center;
    background-size: 20px 20px;
    position: absolute;
    transform: translate(-50%, -50%); } }

.login {
  input {
    &[type="text"], &[type="email"], &[type="password"] {
      width: 100%;
      padding: 14px 10px;
      box-sizing: border-box;
      border: 1px solid #d4d4d4;
      transition: all 0.4s; }
    &[type="text"]:focus, &[type="email"]:focus, &[type="password"]:focus {
      outline: none;
      border: 1px solid #b5b5b5; } }

  .btn_login {
    width: 100%;
    background: #219d57;
    border: none;
    color: white;
    padding: 12px 0;
    font-size: 15px;
    font-weight: normal;
    cursor: pointer;
    border-radius: 2px;
    transition: 0.5s all;
    &:hover {
      background: #1d884c; } }

  .btn_facebook {
    width: 100%;
    cursor: pointer;
    border: none;
    font-size: 15px;
    background: #4a4ac7;
    color: white;
    padding: 12px 0;
    border-radius: 2px;
    transition: .4s all;
    &:hover {
      background: #4444B5; } }

  // span
  //   color: #9a9a9a
  //   display: inline-block
  //   margin-top: 15px
  //   font-size: 18px
  //a
  //  text-decoration: none
  //  margin-left: 5px
  //  color: #817272
  //  transition: 0.3s all
  //  &:hover
 }  //    color: #606060

.agree {
  font-size: 16px;
  color: #9a9a9a; }

.forgot_password {
  border: none;
  background: none;
  color: #606060;
  padding: 5px;
  display: inline-block;
  cursor: pointer;
  margin-top: 10px; }

.block_img {
  width: 100%;
  text-align: center;
  margin: auto;

  // img
  //   width: 158px
 }  //   height: 173px


.login_block_form {
  display: flex;
  flex-direction: column;
  text-align: center;
  line-height: 1.5;
  margin-top: 20px;
  margin-bottom: 30px;

  a {
    // color: #333
    line-height: 1; } }

.login_txt_reg {
  padding: 10px 0;
  p {
    text-align: left;
    font-size: 15px; } }

ol {
  padding: 0;
  margin: 0; }

.manual__btn {
  padding: 7px 18px;
  background: #5e96dc;
  cursor: pointer;
  border: none;
  animation: ManualIcon 2s ease-out infinite;
  height: 35px;
  position: absolute;
  z-index: 2000;
  border-radius: 50px;
  right: 379px;
  top: 107px;
  &:before {
    content: '';
    width: 40px;
    height: 30px;
    position: absolute;
    top: 2px;
    right: -2px;
    background: url(/img/icon_menu/help.svg) no-repeat center center;
    background-size: 30px 30px; } }


.manual__btn_broadcast {
  padding: 7px 18px;
  background: #5e96dc;
  cursor: pointer;
  border: none;
  animation: ManualIcon 2s ease-out infinite;
  height: 35px;
  position: absolute;
  z-index: 500;
  border-radius: 50px;
  left: 419px;
  top: 122px;
  &:before {
    content: '';
    width: 40px;
    height: 30px;
    position: absolute;
    top: 2px;
    right: -2px;
    background: url(/img/icon_menu/help.svg) no-repeat center center;
    background-size: 30px 30px; } }


.manual__btn_rtc {
  padding: 7px 18px;
  background: #5e96dc;
  cursor: pointer;
  border: none;
  animation: ManualIcon 2s ease-out infinite;
  height: 35px;
  position: absolute;
  z-index: 500;
  border-radius: 50px;
  left: 30px;
  top: 5px;
  &:before {
    content: '';
    width: 40px;
    height: 30px;
    position: absolute;
    top: 2px;
    right: -2px;
    background: url(/img/icon_menu/help.svg) no-repeat center center;
    background-size: 30px 30px; } }

.manual__btn_modules_booking {
  padding: 7px 15px;
  background: #5e96dc;
  cursor: pointer;
  border: none;
  //animation: ManualIcon 2s ease-out infinite
  height: 29px;
  z-index: 1;
  border-radius: 50px;
  position: relative;
  &:before {
    content: '';
    width: 33px;
    height: 20px;
    position: absolute;
    top: 4px;
    right: -1px;
    background: url(/img/icon_menu/help.svg) no-repeat center center;
    background-size: 24px 30px; } }


.manual__btn_crm {
  padding: 7px 15px;
  background: #5e96dc;
  cursor: pointer;
  border: none;
  //animation: ManualIcon 2s ease-out infinite
  height: 29px;
  z-index: 1;
  border-radius: 50px;
  position: absolute;
  left: 270px;
  top: 10px;
  &:before {
    content: '';
    width: 33px;
    height: 20px;
    position: absolute;
    top: 4px;
    right: -1px;
    background: url(/img/icon_menu/help.svg) no-repeat center center;
    background-size: 24px 30px; } }


.styleBtn {
  top: 50px; }

.u-relative {
  position: relative; }

.input__z {
  z-index: 1000; }

.manual__popup {
  background-color: #fff;
  border: 1px solid #5e96dc;
  border-radius: 5px;
  box-shadow: 10px 10px 125px 3px rgba(173,173,173,0.2);
  position: absolute;
  visibility: hidden;
  display: inline-block;
  opacity: 0;
  transition: visibility 0s 0s, opacity 0.45s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: scale(0) translate(-516px, 47px);
  padding: 40px 20px;
  z-index: 99999;
  right: -50px;
  top: 40px;
  &.popup_vs {
    visibility: visible;
    transition: visibility 0s 0s, opacity 0.45s cubic-bezier(0.165, 0.84, 0.44, 1);
    transform: scale(1) translate(-516px, 47px);
    opacity: 1; } }


.manual__popup__broadcast {
  background-color: #fff;
  border: 1px solid #5e96dc;
  border-radius: 5px;
  box-shadow: 10px 10px 125px 3px rgba(173,173,173,0.2);
  position: absolute;
  visibility: hidden;
  display: inline-block;
  opacity: 0;
  transition: visibility 0s 0s, opacity 0.45s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: scale(0) translate(-516px, 47px);
  padding: 40px 20px;
  z-index: 99999;
  left: 725px;
  width: 400px;
  top: 380px;
  &.popup_vs {
    visibility: visible;
    transition: visibility 0s 0s, opacity 0.45s cubic-bezier(0.165, 0.84, 0.44, 1);
    transform: scale(1) translate(-516px, 47px);
    opacity: 1; } }


.manual__popup__rtc {
  background-color: #fff;
  border: 1px solid #5e96dc;
  border-radius: 5px;
  box-shadow: 10px 10px 125px 3px rgba(173,173,173,0.2);
  position: absolute;
  visibility: hidden;
  display: inline-block;
  opacity: 0;
  transition: visibility 0s 0s, opacity 0.45s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: scale(0) translate(-516px, 47px);
  padding: 40px 20px;
  z-index: 99999;
  left: 605px;
  width: 400px;
  top: 5px;

  &.popup_vs {
    visibility: visible;
    transition: visibility 0s 0s, opacity 0.45s cubic-bezier(0.165, 0.84, 0.44, 1);
    transform: scale(1) translate(-516px, 47px);
    opacity: 1; } }


.manual__popup__modules {
  background-color: #fff;
  position: absolute;
  visibility: hidden;
  display: inline-block;
  opacity: 0;
  transition: visibility 0s 0s, opacity 0.45s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: scale(0) translate(-516px, 47px);
  padding: 30px 20px;
  z-index: 99999;
  left: 0;
  top: 0;
  &.popup_vs {
    visibility: visible;
    transition: visibility 0s 0s, opacity 0.45s cubic-bezier(0.165, 0.84, 0.44, 1);
    transform: scale(1);
    opacity: 1;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,.5);
    display: flex;
    align-items: center;
    justify-content: center; } }


.content_manual_popup-modules {
  border: none;
  position: relative;
  padding: 40px 40px;
  width: auto;
  z-index: 1000;
  overflow: hidden;
  transition: opacity .3s linear;
  background: #fff; }


.popupTest {
  position: absolute;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 10000;
  background: linear-gradient(5deg,rgba(191,247,255,.45) 0,transparent 100%); }

.popupTest1 {
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 0; }




@keyframes ManualIcon {
  0% {
    //transform: translateX(0px)
    box-shadow: 0 0 0 0 rgba(53,53,156, 0.4); }
  50% {
    //transform: translateX(15px)
    box-shadow: 0 0 0 15px rgba(53,53,156, 0); }
  100% {
    //transform: translateX(0px)
    box-shadow: 0 0 0 0 rgba(53,53,156, 0); } }


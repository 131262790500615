@import "vars";

/*==========  Desktop First  ==========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width : 1200px) {
	/**/ }


/* Medium Devices, Desktops */
@media only screen and (max-width : 992px) {
	/**/ }


/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
	/**/ }


/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px) {
	/**/ }


/* Custom, iPhone Retina */
@media only screen and (max-width : 320px) {
	/**/ }


/*==========  Mobile First  ==========*/

/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {
	/**/ }


/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {
	/**/ }


/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
	/**/ }


/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
	/**/
	// .topnav_left
	// 	width: 20%

	.topnav_center h2 {
		line-height: 18px; } }


/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
	/**/

	.right_add_message_block button {
		font-size: 13px; }

	.topnav_left_navigator .chat_prev {
		font-size: 13px; } }



@media only screen and (min-width : 1024px) and (max-width: 1024px) {
	/**/
	.topnav_center h2:before {
		display: none; }

	.topnav_right ul li {
		padding: 10px 7px; }

	.topnav_right ul li a {
		font-size: 13px; } }

@media only screen and (min-width : 1024px) and (max-width: 1280px) {
	/**/ }


@media only screen and (min-width : 1366px) and (max-width: 1366px) {
	/**/ }

@media only screen and (min-width : 1500px) {
	/**/ }

@media only screen and (min-width : 1440px) and (max-width: 1440px) {
	/**/
	// .topnav_left
 }	// 	width: 10%

@media only screen and (min-width : 1600px) and (max-width: 1600px) {
	/**/ }

@media only screen and (min-width : 2000px) {
	/**/ }




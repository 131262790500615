.section-wrapper {
    border: 1px solid #ced4da;
    background-color: #fff;
    padding: 20px; }

.pd-t-20 {
    padding-top: 20px; }

.pd-t-30 {
    padding-top: 30px; }

.pd-b-20 {
    padding-bottom: 20px; }

.pd-x-20 {
    padding-left: 20px;
    padding-right: 20px; }

.bd {
    border: 1px solid #ced4da; }


.ant-upload {
  font-family: "Monospaced Number", "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 2.2;
  color: rgba(0, 0, 0, 0.65);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  // margin: 0
  padding: 0;
  list-style: none;
  outline: 0;
  p {
    margin: 0; } }

.ant-upload-btn {
  display: block;
  width: 100%;
  outline: none; }

.ant-upload {
  input[type="file"] {
    cursor: pointer; }
  &.ant-upload-select {
    display: inline-block; }
  &.ant-upload-select-picture-card {
    border: 1px dashed #d9d9d9;
    width: 104px;
    height: 104px;
    border-radius: 4px;
    background-color: #fafafa;
    text-align: center;
    cursor: pointer;
    -webkit-transition: border-color 0.3s ease;
    transition: border-color 0.3s ease;
    vertical-align: top;
    margin-right: 8px;
    margin-bottom: 8px;
    display: table;
    > .ant-upload {
      width: 100%;
      height: 100%;
      display: table-cell;
      text-align: center;
      vertical-align: middle;
      padding: 8px; }
    &:hover {
      border-color: #1890ff; } }
  &.ant-upload-drag {
    border: 1px dashed #d9d9d9;
    -webkit-transition: border-color 0.3s;
    transition: border-color 0.3s;
    cursor: pointer;
    border-radius: 4px;
    text-align: center;
    width: 100%;
    height: 100%;
    position: relative;
    padding: 16px 0;
    background: #fafafa;
    &.ant-upload-drag-hover:not(.ant-upload-disabled) {
      border: 2px dashed #40a9ff; }
    &.ant-upload-disabled {
      cursor: not-allowed; }
    .ant-upload-btn {
      display: table;
      height: 100%; }
    .ant-upload-drag-container {
      display: table-cell;
      vertical-align: middle; }
    &:not(.ant-upload-disabled):hover {
      border-color: #40a9ff; }
    p {
      &.ant-upload-drag-icon {
        margin-bottom: 20px;
        .anticon {
          font-size: 48px;
          color: #40a9ff; } }
      &.ant-upload-text {
        font-size: 16px;
        margin: 0 0 4px;
        color: rgba(0, 0, 0, 0.85); }
      &.ant-upload-hint {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.45); } }
    .anticon-plus {
      font-size: 30px;
      -webkit-transition: all .3s;
      transition: all .3s;
      color: rgba(0, 0, 0, 0.25);
      &:hover {
        color: rgba(0, 0, 0, 0.45); } }
    &:hover .anticon-plus {
      color: rgba(0, 0, 0, 0.45); } } }

.ant-upload-list {
  font-family: "Monospaced Number", "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  zoom: 1;
  &:before {
    content: " ";
    display: table; }
  &:after {
    content: " ";
    display: table;
    clear: both;
    visibility: hidden;
    font-size: 0;
    height: 0; } }

.ant-upload-list-item {
  margin-top: 8px;
  font-size: 14px;
  position: relative;
  height: 22px; }

.ant-upload-list-item-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 22px;
  width: 100%;
  display: inline-block; }

.ant-upload-list-item-info {
  height: 100%;
  padding: 0 12px 0 4px;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  > span {
    display: block; }
  .anticon-loading, .anticon-paper-clip {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45);
    position: absolute;
    top: 5px; } }

.ant-upload-list-item .anticon-cross {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  -webkit-transform: scale(0.83333) rotate(0deg);
  -ms-transform: scale(0.83333) rotate(0deg);
  transform: scale(0.83333) rotate(0deg);
  -webkit-transition: all .3s;
  transition: all .3s;
  opacity: 0;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 4px;
  color: rgba(0, 0, 0, 0.45);
  line-height: 22px; }

 :root .ant-upload-list-item .anticon-cross {
  font-size: 12px; }

.ant-upload-list-item {
  .anticon-cross:hover {
    color: rgba(0, 0, 0, 0.65); }
  &:hover {
    .ant-upload-list-item-info {
      background-color: #e6f7ff; }
    .anticon-cross {
      opacity: 1; } } }

.ant-upload-list-item-error {
  color: #f5222d;
  .anticon-paper-clip, .ant-upload-list-item-name {
    color: #f5222d; }
  .anticon-cross {
    opacity: 1;
    color: #f5222d !important; } }

.ant-upload-list-item-progress {
  line-height: 0;
  font-size: 14px;
  position: absolute;
  width: 100%;
  bottom: -12px;
  padding-left: 26px; }

.ant-upload-list-picture .ant-upload-list-item, .ant-upload-list-picture-card .ant-upload-list-item {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  height: 66px;
  position: relative; }

.ant-upload-list-picture .ant-upload-list-item:hover, .ant-upload-list-picture-card .ant-upload-list-item:hover {
  background: transparent; }

.ant-upload-list-picture .ant-upload-list-item-error, .ant-upload-list-picture-card .ant-upload-list-item-error {
  border-color: #f5222d; }

.ant-upload-list-picture .ant-upload-list-item-info, .ant-upload-list-picture-card .ant-upload-list-item-info {
  padding: 0; }

.ant-upload-list-picture .ant-upload-list-item:hover .ant-upload-list-item-info, .ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info {
  background: transparent; }

.ant-upload-list-picture .ant-upload-list-item-uploading, .ant-upload-list-picture-card .ant-upload-list-item-uploading {
  border-style: dashed; }

.ant-upload-list-picture .ant-upload-list-item-thumbnail, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  width: 48px;
  height: 48px;
  position: absolute;
  top: 8px;
  left: 8px;
  text-align: center; }

.ant-upload-list-picture .ant-upload-list-item-icon, .ant-upload-list-picture-card .ant-upload-list-item-icon {
  color: rgba(0, 0, 0, 0.25);
  font-size: 36px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -18px;
  margin-left: -18px; }

.ant-upload-list-picture .ant-upload-list-item-thumbnail img, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  width: 48px;
  height: 48px;
  display: block;
  overflow: hidden; }

.ant-upload-list-picture .ant-upload-list-item-thumbnail.anticon:before, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail.anticon:before {
  line-height: 48px;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.45); }

.ant-upload-list-picture .ant-upload-list-item-name, .ant-upload-list-picture-card .ant-upload-list-item-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 0 0 8px;
  line-height: 44px;
  -webkit-transition: all .3s;
  transition: all .3s;
  padding-left: 48px;
  padding-right: 8px;
  max-width: 100%;
  display: inline-block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.ant-upload-list-picture .ant-upload-list-item-uploading .ant-upload-list-item-name, .ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-name {
  line-height: 28px; }

.ant-upload-list-picture .ant-upload-list-item-progress, .ant-upload-list-picture-card .ant-upload-list-item-progress {
  padding-left: 56px;
  margin-top: 0;
  bottom: 14px;
  width: calc(100% - 24px); }

.ant-upload-list-picture .anticon-cross {
  position: absolute;
  right: 8px;
  top: 8px;
  line-height: 1; }

.ant-upload-list-picture-card {
  .anticon-cross {
    position: absolute;
    right: 8px;
    top: 8px;
    line-height: 1; }
  display: inline;
  &.ant-upload-list:after {
    display: none; }
  .ant-upload-list-item {
    float: left;
    width: 104px;
    height: 104px;
    margin: 0 8px 8px 0; }
  .ant-upload-list-item-info {
    height: 100%;
    position: relative;
    overflow: hidden;
    &:before {
      content: ' ';
      position: absolute;
      z-index: 1;
      background-color: rgba(0, 0, 0, 0.5);
      -webkit-transition: all .3s;
      transition: all .3s;
      width: 100%;
      height: 100%;
      opacity: 0; } }
  .ant-upload-list-item:hover .ant-upload-list-item-info:before {
    opacity: 1; }
  .ant-upload-list-item-actions {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 10;
    white-space: nowrap;
    opacity: 0;
    -webkit-transition: all .3s;
    transition: all .3s;
    .anticon-eye-o, .anticon-delete {
      z-index: 10;
      -webkit-transition: all .3s;
      transition: all .3s;
      cursor: pointer;
      font-size: 16px;
      width: 16px;
      color: rgba(255, 255, 255, 0.85);
      margin: 0 4px; }
    .anticon-eye-o:hover, .anticon-delete:hover {
      color: #fff; } }
  .ant-upload-list-item-info:hover + .ant-upload-list-item-actions, .ant-upload-list-item-actions:hover {
    opacity: 1; }
  .ant-upload-list-item-thumbnail {
    display: block;
    width: 100%;
    height: 100%;
    position: static;
    img {
      display: block;
      width: 100%;
      height: 100%;
      position: static; } }
  .ant-upload-list-item-name {
    margin: 8px 0 0;
    padding: 0;
    text-align: center;
    line-height: 1.5;
    display: none; }
  .anticon-picture + .ant-upload-list-item-name {
    display: block; }
  .ant-upload-list-item-uploading {
    &.ant-upload-list-item {
      background-color: #fafafa; }
    .ant-upload-list-item-info {
      height: auto;
      &:before, .anticon-eye-o, .anticon-delete {
        display: none; } } }
  .ant-upload-list-item-uploading-text {
    margin-top: 18px;
    color: rgba(0, 0, 0, 0.45); }
  .ant-upload-list-item-progress {
    padding-left: 0;
    bottom: 32px; } }


.ant-btn {
  line-height: 2;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 0 15px;
  font-size: 14px;
  border-radius: 4px;
  height: 32px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: relative;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border-color: #d9d9d9;
  i {
  	font-size: 12px;
  	color: #888;
  	margin-right: 5px; } }





.ant-btn {
  > .anticon {
    line-height: 1; }
  outline: 0;
  &:active, &:focus {
    outline: 0; }
  &:not([disabled]) {
    &:hover {
      text-decoration: none; }
    &:active {
      outline: 0;
      -webkit-transition: none;
      transition: none; } }
  &.disabled, &[disabled] {
    cursor: not-allowed; }
  &.disabled > *, &[disabled] > * {
    pointer-events: none; } }

.ant-btn-lg {
  padding: 0 15px;
  font-size: 16px;
  border-radius: 4px;
  height: 40px; }

.ant-btn-sm {
  padding: 0 7px;
  font-size: 14px;
  border-radius: 4px;
  height: 24px; }

.ant-btn {
  > a:only-child {
    color: currentColor;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: transparent; } }
  &:hover, &:focus {
    color: #40a9ff;
    background-color: #fff;
    border-color: #40a9ff;
    i {
    	color: #40a9ff; } }

  &:hover > a:only-child, &:focus > a:only-child {
    color: currentColor; }
  &:hover > a:only-child:after, &:focus > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  &:active, &.active {
    color: #096dd9;
    background-color: #fff;
    border-color: #096dd9; }
  &:active > a:only-child, &.active > a:only-child {
    color: currentColor; }
  &:active > a:only-child:after, &.active > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  &.disabled, &[disabled], &.disabled:hover, &[disabled]:hover, &.disabled:focus, &[disabled]:focus, &.disabled:active, &[disabled]:active, &.disabled.active, &[disabled].active {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9; }
  &.disabled > a:only-child, &[disabled] > a:only-child, &.disabled:hover > a:only-child, &[disabled]:hover > a:only-child, &.disabled:focus > a:only-child, &[disabled]:focus > a:only-child, &.disabled:active > a:only-child, &[disabled]:active > a:only-child, &.disabled.active > a:only-child, &[disabled].active > a:only-child {
    color: currentColor; } }

.bot_top_preview {
	display: flex;
	align-items: center;
	height: 100%; }

.btn_bot_preview {
	display: flex;
	width: 350px;
	button {
		margin: 0 5px;
		border: 1px solid #D2DAE3;
		color: #666;
		font-size: 14px;
		padding: 10px 8px;
		background: #fff; } }


.main_bot_preview {
	width: 100%;
	height: 100%;
	display: flex; }

.main_bot_preview_left {
	background: #EFFDFF;
	width: 450px;
	height: 100vh;
	position: fixed;

	ul {
		li {
			display: block;
			a {
				display: block;
				cursor: pointer;
				padding: 20px 0 20px 80px;
				border-bottom: 1px solid #eaeaea;
				color: #333;
				&:hover {
					text-decoration: none;
					background: #eafcff * 2; } } } } }

.facebook {
	position: relative;
	&:before {
		content: '';
		position: absolute;
		background: url(/img/facebook_bot_icon.svg) no-repeat center center;
		background-size: 35px 35px;
		width: 35px;
		height: 35px;
		top: 10px;
		left: 25px; } }
.skype {
	position: relative;
	&:before {
		content: '';
		position: absolute;
		background: url(/img/skype_bot_icon.svg) no-repeat center center;
		background-size: 35px 35px;
		width: 35px;
		height: 35px;
		top: 10px;
		left: 25px; } }


.site {
	position: relative;
	&:before {
		content: '';
		position: absolute;
		background: url(/img/site_bot_icon.svg) no-repeat center center;
		background-size: 35px 35px;
		width: 35px;
		height: 35px;
		top: 10px;
		left: 25px; } }


.vk_bot {
	position: relative;
	&:before {
		content: '';
		position: absolute;
		background: url(/img/vk_bot_icon.svg) no-repeat center center;
		background-size: 35px 35px;
		width: 35px;
		height: 35px;
		top: 10px;
		left: 25px; } }

.odn {
	position: relative;
	&:before {
		content: '';
		position: absolute;
		background: url(/img/od_bot_icon.svg) no-repeat center center;
		background-size: 35px 35px;
		width: 35px;
		height: 35px;
		top: 10px;
		left: 25px; } }


.telegramm {
	position: relative;
	&:before {
		content: '';
		position: absolute;
		background: url(/img/telegram.svg) no-repeat center center;
		background-size: 35px 35px;
		width: 35px;
		height: 35px;
		top: 10px;
		left: 25px; } }

.viber {
	position: relative;
	&:before {
		content: '';
		position: absolute;
		background: url(/img/viber_bot_icon.svg) no-repeat center center;
		background-size: 35px 35px;
		width: 35px;
		height: 35px;
		top: 10px;
		left: 25px; } }

.alisa {
	position: relative;
	&:before {
		content: '';
		position: absolute;
		background: url(/img/alisa.svg) no-repeat center center;
		background-size: 35px 35px;
		width: 35px;
		height: 35px;
		top: 10px;
		left: 25px; } }

.main_bot_preview_right {
	width: 83%;
	margin-left: 450px;
	background: #fff;
	.grow_main {
		padding: 0; } }
.main_bot_preview_right_content {
	padding: 10px 20px;

	h4 {
		width: 100%;
		//letter-spacing: 1px
		padding: 5px 0;
		font-size: 18px;
		font-family: 'CenturyGothic-Bold';
		line-height: 30px;
		margin: 0; }

	input {
		width: 100%;
		padding: 7px 20px;
		border: 1px solid #D2DAE3;
		margin: 5px 0; } }

.tooltip_bot {
	display: block;
	font-size: 14px;
	padding: 6px 0;
	margiт: 10px 0 30px 0;
	&:hover {
		text-decoration: none; }
	span {
		color: #666;
		display: inline-block;
		border-bottom: 1px dashed #999;
		font-size: 13px; } }

.create_facebook {}

.create_bot_help {
	margin-bottom: 20px;
	a {
		border-bottom: 1px dashed #337ab7;
		&:hover {
			text-decoration: none; } } }
.__react_component_tooltip {
	border: 1px solid #D2DAE3!important;
	background: #fff!important;
	box-shadow: -1px 9px 14px 0 rgba(0,0,0,.1)!important;
	color: #333!important;
	z-index: 9999!important; }

.content_preview_text {
	padding: 10px 0 20px 0;
	h3 {
		font-size: 15px;
		color: #333;
		font-family: 'CenturyGothic-Bold'; }
	p {
		color: #666; } }


.dashboard-bot_list_bot {
	display: flex;
	flex-wrap: wrap;
	transition: all .4s; }


.broadcast_contest {
	margin-top: 10px; }

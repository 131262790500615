
.template {
  border: 1px solid #ddd;
  background-color: white;
  margin-right: 15px;
  margin-bottom: 15px;
  width: 370px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;

  .template_image {
    max-width: 80px;

    img {
      width: 100%;
    }
  }

  .template_content_wrap {
    padding-left: 20px;
  }

  .template_content {
    display: flex;
    flex: 1;
    margin-bottom: 10px;

    .template_content_title {
      font-size: 20px;
      margin-bottom: 10px;
      font-weight: bold;
      min-height: 30px;
    }

    .template_content_description {
    }
  }

}
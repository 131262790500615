.footer-pagination {
  .pagination {
    a {
      padding: 5px 15px;
      color: #333;
      border: 1px solid #d2dae3;
      transition: all .3s linear;
      display: inline-block;
      background-color: #fff;
      border-radius: 3px;
      cursor: pointer;
      margin: 0 2px;
    }
  }
}


.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
  background: #6b7074;
  border-radius: 8px;
  border: none;
  height: 64px;
  cursor: pointer;

  &.link {
    opacity: 0.25;
    pointer-events: none;
  }

  &.payment-button-active {
    opacity: 1;
  }

  &:hover {
    background-color: #2743CE;
  }

  &.active {
    background-color: #435CDB;
  }
  
  &:disabled {
    opacity: 0.25;
  }

  img {
    max-width: 95px;
    margin-left: 15px;
  }

}


 :root {
    --cl-white-default: #fff;
    --cl-white-opacity-05: rgba(255,255,255, 0.5);
    --cl-secondary-cultured-default: #F8F9FB;
    --cl-primary-persian-blue-hover: #2743CE;
    --cl-primary-persian-blue-default: #243CBB;
    --cl-primary-persian-blue-focus: #314DD8;
    --cl-primary-persian-blue-press: #435CDB;
    --cl-drop-shadow-medium: rgba(21, 18, 51, 0.13);
    --cl-primary-oxford-blue-default: #060C23;
    --cl-primary-medium-spring-green-default: #22FFAA; }


// colors
.yel {
  color: #069f02; }
.blue {
  color: #6771ff; }
.purple {
  color: #9280ff; }

.main_deliver {
  overflow: auto;
  height: 100vh; }
.main_header {
  background: url("/img/header-robot.jpg") no-repeat center center;
  height: 855px;
  width: 100%;
  padding: 0;
  margin: 0; }

.bs-navbar {
  color: white; }

.content_header {
  padding: 16em 0 0 0;
  h1 {
    color: white;
    font-family: 'CenturyGothic-Bold';
    font-size: 30px;
    line-height: 35px; }
  p {
    font-size: 17px; } }

.main_top_btn {
  background: linear-gradient(to right, #49c3ff 0%,#7257ff 100%);
  display: inline-block;
  padding: 1px 12px;
  border-radius: 2px;
  a {
    color: white; } }

.main_menu_header {
  padding: 1.4em 0 0 0; }

nav {
  li {
    a {
      color: white; } } }

.items_comfort {
  h4 {
    color: #666;
    font-size: 18px;
    padding: 15px 0 5px 0;
    font-family: "FuturaPT-Book"; }
  p {
    color: #666; } }


.items_text_header {
  padding: 1em 0 0 0;
  p {
    color: #dddddd; }
  span {
    font-family: 'CenturyGothic-Bold'; } }


.img_items_center {
  text-align: center; }


.comfortable {
  text-align: center;
  padding: 2em 0 0 0;
  h2 {
    padding: 1.5em 0 2em 0;
    font-family: 'CenturyGothic-Bold'; } }

.program_text_container {
  p {
    padding: 10px 0 10px 0;
    color: #666;
    font-size: 16px; } }


.program {
  margin-top: 6em;
  background: #eeeeee;
  padding: 5em 0 5em 0;

  h2 {
    text-align: center;
    font-family: 'CenturyGothic-Bold';
    padding: 0 0 40px 0; } }

.programm_item {
  width: 100%;
  height: 100%;
  margin: 10px 0 10px 0;
  padding: 10px 0 10px 0; }

.programm_item_img {
  text-align: center;

  img {
   text-align: center; } }


.programm_item_text {
  text-align: left;
  h4 {
    font-family: "FuturaPT-Book";
    color: #666;
    font-size: 16px; }
  p {
    color: #666; } }


.tariffs {
  padding: 105px 0 140px 0;
  h2 {
    text-align: center;
    font-family: 'CenturyGothic-Bold'; } }

.tariffs_item {
  margin: 0 auto;
  width: 80%;
  padding: 60px 20px 70px 20px;
  border: 1px solid #ccc;
  h3 {
    text-align: center;
    font-family: 'CenturyGothic-Bold'; } }


.tariffs_btn {
  display: inline-block;
  padding: 12px 20px;
  border-radius: 2px;
  background: linear-gradient(to right, #49c3ff 0%,#7257ff 100%);
  color: white;
  transition: all .3s;
  width: 100%;
  &:hover {
    text-decoration: none;
    color: white;
    box-shadow: 0 4px 20px rgba(0,0,0,.3); } }

.btn_overlay {
  text-align: center;
  width: 100%; }


.bs-docs-footer-links {
  padding-left: 0;
  margin-bottom: 20px;
  li {
    display: inline-block;
    padding: 5px 10px;
    vertical-align: middle;
    a {
      color: #666;
      margin-top: 7px;
      display: inline-block; } } }

.main_footer {
  background: #dddddd;
  padding: 40px 0 50px 0; }

.nav>li>a:focus,
.nav>li>a:hover {
  text-decoration: none;
  background: rgba(255,255,255,.1);
  color: white; }

.nav>li:last-child>a:hover {
  background: none;
  /* style */ }


$btnBlueColor: #5E96DC;
$RbtnColor: #433fb8;
$RhoverbtnColor: #35359c;
$PopupWhiteColor: #fff;
$fzContent: 1.6rem;
$h1: 25px;
$size13: 13px;
$fontBold: 'CenturyGothic-Bold';


.bg_shadow {
	background-color: $RbtnColor!important;
	color: white!important;

	&:hover {
		background-color: #2a1794 !important; } }



.active {
  background-color: #eaeaea!important; }

h1 {
  font-size: $h1;
  font-family: $fontBold; }

h3 {
  font-size: 18px;
  font-family: $fontBold; }

.fw_menu {
  font-family: $fontBold; }

input[type="checkbox"] {
	cursor: pointer;
	margin: 0!important; }

input[type=range] {
	background: #5e96dc; }

a:focus,
a:hover {
  text-decoration: none; }

.new-message {
  display: none!important; }

.chat_input {
  width: 100%;
  padding: 7px 10px;
  box-sizing: border-box;
  margin: 5px 0;
  border: 1px solid #d2dae3;
  transition: all .4s;

  &:focus {
	  outline: none;
	  border: 1px solid #B5B5B5; } }

@mixin btn-all {
  padding: 10px 25px;
  cursor: pointer;
  position: relative;
  color: #333;
  border: 1px solid #dfdfdf;
  transition: .3s linear all;
  background-color: #FFF;
  border-radius: 3px;
  display: inline-block;

  &:hover {}
	 border: 1px solid #dfdfdf;

  &:focus {}
	 outline: none; }

.btn_all {
	@include btn-all; }

button:focus {
	outline: none; }

@mixin btn__all--small {
	padding: 7px 15px;
	color: #333;
	border: 1px solid #d2dae3;
	transition: .3s linear all;
	display: inline-block;
	background-color: #FFF;
	border-radius: 3px;
	cursor: pointer;
	&:hover {
		border: 1px solid #dfdfdf;
		background-color: #eaeaea;

  &:focus {}
		outline: none; } }

.btn__all--small {
  @include btn__all--small; }

@mixin icon_manual {}

@mixin button_api_add {
  width: 100%;
  font-size: $size13;
  padding: 12px 0;
  display: inline-block;
  color: white;
  border: none;
  border-radius: 3px;
  background: $RbtnColor;
  transition: 0.4s all;
  margin-top: 10px;
  position: relative;
  cursor: pointer; }

.api_textarea {
	width: 100%; }

.border_textarea_ {
  border: 1px solid #d4d4d4; }

.userFilter {
	display: inline-block;
	cursor: pointer;
	padding: 0 25px 0 45px;
	margin: 0 0 0 14px;
	width: auto;
	height: 36px;
	line-height: 36px;
	border-radius: 2px;
	background-color: #fff;
	font-size: 14px;
	color: #333;
	border: 1px solid #d0d0d0;
	position: relative;

	&:before {
		content: '';
		position: absolute;
		top: 8px;
		left: 17px;
		width: 18px;
		height: 18px;
		background: url(/img/user2.svg) no-repeat center center;
		background-size: 18px 18px; } }

.sendMessage {
	display: inline-block;
	padding: 0 45px 0 25px;
	margin: 0 20px 0 0;
	width: auto;
	height: 36px;
	line-height: 36px;
	border-radius: 2px;
	background-color: #ccc;
	box-shadow: 0 1px 2px 0 rgba(0,0,0,.07);
	font-size: 14px;
	color: #fff;
	border: none;
	position: relative;
	cursor: pointer;

	&:before {
		content: '';
		position: absolute;
		top: 8px;
		right: 17px;
		width: 18px;
		height: 18px;
		background: url(/img/send.svg) no-repeat center center;
		background-size: 18px 18px; } }

.broadCast_btn {
  padding: 15px 0;
  display: flex; }

.btn_save {
	display: inline-block;
	padding: 0 25px 0 25px;
	margin: 0 10px 0 0;
	width: auto;
	height: 43px;
	line-height: 43px;
	border-radius: 2px;
	background-color: $RbtnColor;
	font-size: 14px;
	color: #fff;
	position: relative;
	transition: all .4s;
	border: none;
	&:hover {
		background-color: $RhoverbtnColor; } }

.btn_delete {
	display: inline-block;
	padding: 0 25px 0 25px;
	width: auto;
	height: 43px;
	line-height: 43px;
	border-radius: 2px;
	background-color: #fff;
	border: 1px solid #4444B8;
	font-size: 14px;
	color: #fff;
	position: relative;
	transition: all .4s;
	&:hover {
		box-shadow: 0 5px 11px 0 rgba(0,0,0,0.2); }
	&:before {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		right: 17px;
		width: 18px;
		height: 18px;
		transform: translate(-50%,-50%);
		background: url(/img/delete.svg) no-repeat center center;
		background-size: 18px 18px; } }

.orange-rounded {
	background-color: #e9604a;
	border: none!important;
	color: #fff;
	translate: .3s all linear;
	&:hover {
		background-color: #dd543e; } }

.react-tabs__tab-list {
	border-bottom: 1px solid #aaa;
	margin: 0 0 10px;
	padding: 0; }

.react-tabs__tab {
	display: inline-block;
	border: 1px solid transparent;
	border-bottom: none;
	bottom: -1px;
	position: relative;
	list-style: none;
	padding: 6px 12px;
	cursor: pointer; }

.react-tabs__tab--selected {
	background: #fff;
	border-color: #aaa;
	color: black;
	border-radius: 5px 5px 0 0; }

.react-tabs__tab-panel {
	display: none; }

.react-tabs__tab-panel--selected {
	display: block; }

.react-tabs__tab--disabled {
	color: GrayText;
	cursor: default; }

.react-tabs__tab:focus {
	box-shadow: 0 0 5px hsl(208, 99%, 50%);
	border-color: hsl(208, 99%, 50%);
	outline: none; }

.react-tabs__tab:focus:after {
	content: "";
	position: absolute;
	height: 5px;
	left: -4px;
	right: -4px;
	bottom: -5px; }

.grow_btn {
	padding: 7px 21px;
	color: #333;
	background-color: #fff;
	transition: .3s linear all;
	border: 1px solid #dfdfdf;

	&:focus {
		outline: none; } }

.grow_input {
	border: 1px solid #dfdfdf; }

.color_btn_1 {
	background-color: #d2dae3!important; }

.popupW {
	background-color: $PopupWhiteColor; }

.broad_view_message {
	position: relative;
	height: calc(50vh - 60px);
	overflow-y: auto;
	padding: 0; }

.view_message {
	height: 80%;
	display: flex;
	align-items: center;
	justify-content: center; }

.view_message_center {
	height: 20rem; }

.row__grid {
	flex-wrap: wrap;
	display: flex;
	position: relative; }

.justify__content--between {
	justify-content: space-between; }

.justify__content--end {
	justify-content: flex-end; }

.grid__align--center {
	align-items: center; }

.grid__align--baseline {
	align-items: baseline; }

.grid__justify--content {
	justify-content: center; }

.grid__api--delete {
	padding: 15px 0;
	position: relative;
	width: 100%;
	display: block;

	.close {
		position: absolute;
		top: 5px;
		right: 5px; } }

.grid__br--2 {
  border-right: 1px solid #effdff;
  border-color: #effdff; }

.grid__bl--2 {
  border-right: 1px solid #effdff;
  border-color: #effdff; }

.grid_h-100 {
  height: 100%; }

.grid_w-100 {
  width: 100%; }

.col__1 {
  flex: 0 0 10%;
  max-width: 10%; }

.col__2 {
  flex: 0 0 20%;
  max-width: 20%; }

.col__3 {
  flex: 0 0 30%;
  max-width: 30%; }

.col__4 {
  flex: 0 0 40%;
  max-width: 40%; }

.col__5 {
  flex: 0 0 50%;
  max-width: 50%; }

.col__6 {
  flex: 0 0 60%;
  max-width: 60%; }

.col__7 {
  flex: 0 0 70%;
  max-width: 70%; }

.col__8 {
  flex: 0 0 80%;
  max-width: 80%; }

.col__9 {
  flex: 0 0 90%;
  max-width: 90%; }

.col__10 {
  flex: 0 0 100%;
  max-width: 100%; }

.grid__offset--2 {
  margin-left: 20%; }

.grid__input {
  width: 100%;
  border: 1px solid #d2dae3;
  padding: 7px 10px; }

.grid__mt--5 {
  margin-top: 5px; }

.grid__list--bots ul li {
	padding: 10px 0 10px 0;
	border-bottom: 1px solid #d2dae3; }

.grid_text--b {
  display: inline-block;
  color: #337ab7;
  cursor: pointer; }

.grid_button_blue {
	padding: 8px 15px;
	cursor: pointer;
	border-radius: 2px;
	background-color: #5E96DC;
	display: inline-block;
	border: none;
	color: white;
	transition: all .2s;
	&:hover {
		background-color: #5689ca; } }

.grid__edit--table {
  background: url(/img/edit1.svg) no-repeat center center;
  background-size: 16px 16px;
  padding: 12px 15px;
  margin: 0 1px;
  border: none;
  display: inline-block; }

.grid__delete--table {
  background: url(/img/delete.svg) no-repeat center center;
  background-size: 16px 16px;
  padding: 12px 15px;
  margin: 0 1px;
  border: none;
  display: inline-block; }

.Run_icon {
  background: url(/img/media_icon_room/down-button.svg) no-repeat center center;
  background-size: 20px 20px;
  padding: 10px 10px;
  border: none;
  display: inline-block; }

.Stop_icon {
  background: url(/img/media_icon_room/ic_stop_black_24px.svg) no-repeat center center;
  background-size: 20px 20px;
  padding: 10px 10px;
  border: none;
  display: inline-block; }

.Check_icon {
  background: url(/img/media_icon_room/ic_av_timer_black_24px.svg) no-repeat center center;
  background-size: 20px 20px;
  padding: 10px 10px;
  border: none;
  display: inline-block; }

.Reject_icon {
  background: url(/img/media_icon_room/ic_cancel_black_24px.svg) no-repeat center center;
  background-size: 20px 20px;
  padding: 10px 10px;
  border: none;
  display: inline-block; }

.grid__btn_api--delete {
  position: absolute;
  top: 0;
  right: 10px;
  width: 30px;
  height: 30px;
  background-color: #555;
  cursor: pointer; }

.grid__btn--delete {
	display: inline-block;
	padding: 9px 25px 9px 25px;
	width: 100%;
	height: 100%;
	border-radius: 2px;
	background-color: #fff;
	border: 1px solid #4444b8;
	font-size: 13px;
	color: #fff;
	position: relative;
	transition: all .4s;

	&::before {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		right: 17px;
		width: 18px;
		height: 18px;
		transform: translate(-50%,-50%);
		background: url(/img/delete.svg) no-repeat center center;
		background-size: 18px 18px; } }

.grid__btn--api {
	background-color: $btnBlueColor;
	display: inline-block;
	padding: 4px 5px;
	color: white;
	border-radius: 3px;
	border-color: #effdff;
	border-bottom: none;
	transition: all .4s;
	font-size: 13px;
	cursor: pointer;
	&:hover {
		background-color: $btnBlueColor + 1; } }

.heading__caption_1 {
  font-size: 14px;
  color: #444;
  font-family: $fontBold;
  display: block;
  padding: 6px 0; }

.form-row {
  margin-bottom: 20px;
  padding: 4px 0;
  position: relative; }

.form-block {
  border: 1px solid #cccccc;
  margin-bottom: 8px;
  padding: 8px 12px; }

.input-text {
  background-color: white;
  -webkit-appearance: none;
  border: 1px solid #cccccc;
  margin-bottom: 8px;
  padding: 8px 4px;
  position: relative;
  width: 100%;
  z-index: 3; }

.input-text:focus {
  box-shadow: 0 0 6px rgba(0, 200, 222, 0.25);
  background-color: #fafafa; }

.label-helper {
  position: absolute;
  opacity: 0;
  transition: 0.2s bottom, 0.2s opacity;
  bottom: 0;
  left: 0;
  z-index: 1; }

.input-text:focus + .label-helper,
.input-text:invalid + .label-helper {
  bottom: 95%;
  font-size: 14px;
  line-height: 1;
  opacity: 1;
  padding: 4px; }

.input-text:invalid {
  border-left: 10px solid #f00; }

.input-text:invalid + .label-helper::after {
  color: #f00;
  content: "X";
  font-size: 14px;
  line-height: 1;
  padding-left: 12px; }

.p {
  text-align: center;
  padding-top: 50px; }

.chat_api {
	padding: 10px 10px 7px  10px;
	border: 1px solid #d2dae3;
	width: 100%;
	&::-webkit-input-placeholder {
		font-size: 13px; } }

.overflow_main_bot {
	display: -ms-flexbox;
	display: flex;
	justify-content: space-between;
	height: calc(100vh - 55px);
	width: 100%; }

.main_bot_center_content {
	width: 10700px;
	height: 14000px; }

.overflow_main_bot_center {
	background: 0 0;
	background-size: 25px 25px;
	height: calc(100vh - 69px);
	width: 100%;
	min-height: calc(100vh - 69px);
	overflow: scroll;
 }	//padding: 0 0 0 193px

.grid__media--image {
	max-width: 200px;
	max-height: 200px;

	img {
		width: 100%;
		height: auto;
		display: block; } }

.grid__file {
	display: none!important; }

.custom-file-upload {
	border: 1px solid #d2dae3;
	display: inline-block;
	padding: 6px 12px;
	cursor: pointer;
	transition: all .3s;

	&:hover {
	  background-color: #e9f2fb; } }

.grid_underline {
  padding: 0 0 3px 0;
  border-bottom: 1px solid #337ab7; }

.a {
	color: #1890ff;
	cursor: pointer; }

.preloader_bots {
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: rgba(0,0,0,.7);
  top: 0;
  left: 0;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center; }

.popupW {
  background-color: #fff; }

.preloader_bots_2 {
  border: none;
  min-width: 600px;
  max-width: 600px;
  position: relative;
  text-align: center;
  width: auto;
  z-index: 1000;
  overflow: hidden;
  transition: opacity .3s linear; }

.analyse_content {
  min-height: 6rem; }

.analyse_content_2 {
  padding: 25px 25px 10px 25px; }

.analyse_content {
  height: 35rem;
  padding: 10px 0 10px 0; }

.close_popup_btn {
  position: absolute;
  top: 10px;
  right: 20px; }

.list_modules {
	li {
		display: inline-block;
		padding: 5px 10px;
		background-color: #eee;
		margin: 0 3px;
		border: 1px solid #d2dae3;
		cursor: pointer;
		border-radius: 2px;
		&:hover {
			background-color: #dae1e8; }

		&.active {
			background-color: #dae1e8!important; } }

	&:nth-child(1) {
	  margin: 0 3px 0 0; } }

.delete_gray {
	background: url(/img/ic_clear_black_18px.svg) no-repeat center center;
	background-size: 16px 16px;
	padding: 17px 25px;
	margin: 0 1px;
	display: inline-block;
	border: 1px solid #d2dae3; }

.gray_m {
  margin: 0 0 0 16px; }

.list_bot_crm {
  width: 250px; }

.buttom__build__message {
	cursor: pointer;
	border: none;
	padding: 5px 8px;
	background: none;

	&:focus {
		outline: none; } }

.grid__api--delete {}
.close {
  position: absolute;
  top: 5px;
  right: 5px; }

button.close {
	-webkit-appearance: none;
	padding: 0;
	cursor: pointer;
	background: 0 0;
	border: 0; }

.close {
	float: right;
	font-size: 21px;
	font-weight: 700;
	line-height: 1;
	color: #000;
	text-shadow: 0 1px 0 #fff;
	opacity: .2; }

input[type=checkbox],
input[type=radio] {
  margin: 4px 0 0;
  line-height: normal; }

.react-date-picker__calendar {
  z-index: 999; }

.file {
  cursor: pointer;
  width: 180px;
  height: 34px;
  overflow: hidden; }

.file::before {
  width: 180px;
  height: 32px;
  font-size: 13px;
  line-height: 32px;
  content: 'Выбрать файл';
  display: inline-block;
  background: white;
  border: 1px solid #ced4da;
  padding: 0 10px;
  text-align: center; }

.file::-webkit-file-upload-button {
  visibility: hidden; }

.rts_center {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center; }

.table__fixed {
	tr {
		word-wrap: break-word; } }

.ctn_wrap {
  height: 70vh;
  position: relative;
  overflow: hidden; }

.button__message {
	height: 79px;
	border: 1px solid #d2dae3;
	padding: 7px 10px;
	margin: 5px 0;
	cursor: pointer;
	display: flex;
	align-items: center;
	transition: all .4s;
	background-color: #fff;
	&:hover {
		border: 1px solid #4444b8; }
	i {
		color: #666; } }

.button__message_textarea {
	width: 100%;
	padding: 7px 10px;
	box-sizing: border-box;
	margin: 5px 0;
	border: 1px solid #d2dae3;
	transition: all .4s;
	&:focus {
		border: 1px solid #4444b8;
		outline: none; } }

.chat_message {
	border: 1px solid #dfdfdf;
	padding: 10px 20px 10px 5px;
	margin-bottom: 10px;
	background-color: #fff;
	box-shadow: 3px -1px 66px -5px rgba(150,150,150,0.2);

	.chat_name {
		text-align: left;
		font-family: $fontBold;

 	.chat_text {}
		padding: 10px 0;

 	.chat_text_ins {}
		padding: 10px 0;

 	.chat_date {}
		font-size: 12px;
		color: #777;

  .message_buttom {}
		border: 1px solid #eee;
		font-size: 13px;
		cursor: pointer; } }

.chat_icon--avatar {
	width: 40px;
	height: 40px;
	border-radius: 150px;
	background-color: #eee;
	margin: auto;
	img {
		width: 40px;
		height: 40px;
		border-radius: 50%;

 	&.user {}
		background: url(/img/avatar_chat/user.svg) no-repeat center center;
		background-size: 40px 40px; }
	&.bot {
		background: url(/img/avatar_chat/robot-face.svg) no-repeat center center;
		background-size: 40px 40px; } }

.mini_admin {
	background: url(/img/avatar_chat/admin.svg) no-repeat center center;
	background-size: 25px 25px; }

.chat_message--left {
  border: 1px solid red; }

.file_message {
  cursor: pointer;
  width: 100%;
  height: 79px;
  margin: 5px 0;
  overflow: hidden; }

.file_message:before {
  width: 100%;
  height: 79px;
  font-size: 13px;
  line-height: 32px;
  content: '';
  display: inline-block;
  background: url(/img/avatar_chat/clip.svg) no-repeat center center;
  background-size: 20px 20px;
  border: 1px solid #d2dae3;
  padding: 0 10px;
  text-align: center; }

.file::-webkit-file-upload-button {
  visibility: hidden; }

.MessageList {
	overflow-y: scroll; }

.left__user__chat--block {
	border: 1px solid #dfdfdf;
	cursor: pointer;
	position: realative;
	margin-bottom: 5px;
	padding: 5px 5px 15px 5px;
	&:hover {
		background-color: #eee; } }

.avatar_add_bot_ad {
	text-align: center;
	display: flex;
	align-items: center;

	img {
		width: 25px;
		height: 25px;
		border-radius: 50%; } }

.left__user__chat--name {
	font-size: 13px; }

.left__user__chat--answer {
	color: #666;
	font-size: 12px; }

.left__user__chat--answer--2 {
	font-size: 11px;
	color: #666; }

.chat__avatar__ad__bot {
	img {
		width: 30px;
		height: 30px;
		border-radius: 50%;
		border: 1px solid #eaeaea; } }

.dialog_message_block {
	height: 20vh;
	display: block;
	text-align: center;
	margin: auto; }


.chat_message_avatar {
	img {
		width: 40px;
		height: 40px;
		border-radius: 50%; } }

// widget social

.Vk {
	display: inline-block;
	background-size: 35px 35px;
	width: 35px;
	height: 35px;
	background: url(/img/vk_bot_icon.svg) no-repeat center center; }

.Telegram {
	display: inline-block;
	background-size: 35px 35px;
	width: 35px;
	height: 35px;
	background: url(/img/telegram.svg) no-repeat center center; }

.Viber {
	display: inline-block;
	background-size: 35px 35px;
	width: 35px;
	height: 35px;
	background: url(/img/viber_bot_icon.svg) no-repeat center center; }

.FB {
	display: inline-block;
	background-size: 35px 35px;
	width: 35px;
	height: 35px;
	background: url(/img/facebook_bot_icon.svg) no-repeat center center; }

.Skype {
	display: inline-block;
	background-size: 35px 35px;
	width: 35px;
	height: 35px;
	background: url(/img/skype_bot_icon.svg) no-repeat center center; }

.widget_inflame {
	border: none;
	min-width: 400px;
	min-height: 245px; }

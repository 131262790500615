.c-login {
    background: var(--cl-secondary-cultured-default);
    position: relative;

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0; }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield; }

    .c-login__top-block {
        background: var(--cl-white-default);
        position: absolute;
        width: 100%;
        height: 373px;
        top: 0;
        left: 0;
        z-index: 0; }

    .c-login__form-block {
        background: var(--cl-primary-persian-blue-hover);
        width: 100%;
        max-width: 770px;
        box-shadow: 0px 12px 24px var(--cl-drop-shadow-medium);
        border-radius: 30px;
        z-index: 1;
        box-sizing: border-box;

        @media (max-width: 768px) {
            max-width: 700px; } }

    .c-login__form-block--manual {
        background: var(--cl-primary-persian-blue-default);
        width: 100%;
        border-radius: 30px;
        padding: 60px 100px;
        box-sizing: border-box;
        position: relative;
        overflow: hidden;
        @media (max-width: 700px) {
            padding: 40px 20px; }

        .c-registration__create-account-bot {
            position: absolute;
            bottom: 0;
            right: -73px;
            @media (max-width: 700px) {
                right: 50%;
                transform: translateX(50%); } } }

    .c-login__logo-link {
        z-index: 1;
        margin: 40px 0; }

    .c-login__login-text, .c-registration__login-text {
        position: absolute;
        top: -74px;
        left: 50%;
        transform: translateX(-50%);
        background-color: var(--cl-primary-persian-blue-default);
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.5px;
        color: var(--cl-white-default);
        border-radius: 15px;
        padding: 5px 20px;
        z-index: 2; }

    .c-registration__login-text {
        bottom: -93px;
        top: auto; }

    h1 {
        z-index: 1;
        font-style: normal;
        font-weight: 500;
        font-size: 48px;
        line-height: 110%;
        margin-bottom: 30px;
        text-align: center;

        @media (max-width: 768px) {
            font-size: 32px; } }

    .c-login__input {
        width: 100%; }

    .c-login__login-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 20px 50px;
        border-radius: 12px;
        height: 64px;
        box-sizing: border-box;
        font-weight: 600;
        font-size: 18px;
        line-height: 134%;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin-top: 50px; }

    .c-login__paragraph-white {
        color: var(--cl-white-default);
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 130%;
        text-align: center; }

    .form-group {
        width: 100%; }

    .c-login__links-block {
        a {
            margin: 0 15px;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 150%;
            letter-spacing: 0.3px;
            text-decoration-line: underline;
            color: var(--cl-white-default); } }

    .c-login__social-block {
        margin: 55px 0 60px;
        width: 100%;
        padding: 0 115px;
        box-sizing: border-box;
        position: relative;

        @media (max-width: 768px) {
            padding: 0 80px; }

        @media (max-width: 700px) {
            padding: 0 20px; }

        i {
            color: var(--cl-white-default);
            font-size: 16px; } }

    .c-login__social-buttons-stack {
        width: auto;
        border-radius: 30px;
        padding: 8px;
        background-color: var(--cl-primary-persian-blue-focus);

        >span {
            display: inline-block; } }


    .c-login__social-item {
        background: var(--cl-primary-persian-blue-default);
        width: 40px;
        height: 40px;
        border-radius: 40px;
        border: none;
        position: relative;
        cursor: pointer;
        padding: 0;
        vertical-align: text-bottom; }

    .c-login__reset-password-image {
        margin-top: -20px;
        z-index: 3; } }

.c-login__success-screen {
    background-color: var(--cl-primary-persian-blue-default);
    height: 100vh;
    padding: 0 135px;
    @media (max-width: 768px) {
        padding: 0 30px; }

    h2 {
        color: var(--cl-white-default);
        font-style: normal;
        font-weight: 500;
        font-size: 64px;
        line-height: 110%;
        letter-spacing: -1px; }

    .c-login__success-block-info {
        max-width: 570px;
        @media (max-width: 768px) {
            font-size: 32px;
            max-width: 100%; } }

    .c-login__success-block-info-email {
        font-size: 32px;
        background: var(--cl-primary-persian-blue-press);
        color: var(--cl-primary-medium-spring-green-default);
        border-radius: 8px;
        padding: 0px 13px 7px 7px;
        line-height: 1;
        display: inline-block;
        vertical-align: middle;
        @media (max-width: 768px) {
            font-size: 20px; } }



    .c-login__close-button {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer; } }

.c-registration__inputs-block {
    width: 100%; }

.c-login__error-message {
    position: absolute;
    top: 20px; }

.c-registration__login-suggest {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.3px;
    color: var(--cl-white-default);
    margin-top: 50px;
    margin-bottom: 0;
    @media (max-width: 768px) {
        margin-top: 30px; }

    a, a:hover {
        text-decoration: underline;
        color: var(--cl-white-default);
        margin: 0 10px; } }

.c-login.c-registration {
    @media (max-width: 700px) {
        .c-login__form-block--manual {
            padding: 40px 20px 170px; } } }

.c-login__input-adornment {
    cursor: pointer; }

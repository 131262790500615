@font-face {
  font-family: 'CenturyGothic';
  src: url('/fonts/Gothic/CenturyGothic.eot') format('embedded-opentype'),
  url('/fonts/Gothic/CenturyGothic.woff') format('woff'),
  url('/fonts/Gothic/CenturyGothic.ttf') format('ttf') {}
  font-weight: normal;
  font-style: normal; }


@font-face {
  font-family: 'CenturyGothic-Bold';
  src: url('/fonts/Gothic-Bold/CenturyGothic-Bold.eot') format('embedded-opentype'),
  url('/fonts/Gothic-Bold/CenturyGothic-Bold.woff') format('woff'),
  url('/fonts/Gothic-Bold/CenturyGothic-Bold.ttf') format('ttf') {} }


@font-face {
  font-family: "FuturaPT-Book";
  src: url("/fonts/Futura/FuturaPT-Book.eot") format("embedded-opentype"),
  url("/fonts/Futura/FuturaPT-Book.woff") format("woff"),
  url("/fonts/Futura/FuturaPT-Book.ttf") format("truetype") {}
  font-style: normal;
  font-weight: normal; }


@font-face {
  font-family: "Monospaced Number";
  src: local("Tahoma");
  unicode-range: U+30-39; }

@font-face {
  font-family: "Chinese Quote";
  src: local("PingFang SC"), local("SimSun");
  unicode-range: U+2018, U+2019, U+201c, U+201d; }

@font-face {
  font-family: 'CenturyGothic-Bold';
  src: url('/fonts/Gothic-Bold/CenturyGothic-Bold.eot') format('embedded-opentype'),
  url('/fonts/Gothic-Bold/CenturyGothic-Bold.woff') format('woff'),
  url('/fonts/Gothic-Bold/CenturyGothic-Bold.ttf') format('ttf') {} }


@font-face {
  font-family: 'IBMPlexMono-Bold';
  src: url('/fonts/IBMPlexMono/IBMPlexMono-Bold.ttf'); }

@font-face {
  font-family: 'IBMPlexMono-Regular';
  src: url('/fonts/IBMPlexMono/IBMPlexMono-Regular.ttf'); }

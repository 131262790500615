
.mainBroadCast {
	background: #fff;
	position: absolute;
	width: 100%;
	//height: 100vh
	padding: 85px 0 0 193px;
	display: flex;

	clearfix::after {
		content: "";
		clear: both;
		display: table; } }

.broadcast_left_panel {
	background-color: #fff;
	align-items: stretch;
	display: flex;
	position: relative;
	height: calc(100vh - 82px); }

.broadcast_left_panel_post {
  background-color: #fff;
  padding: 0 15px 0 0;
  // padding: 32px 15px
  min-height: 100%;
  width: 100%;
  overflow-x: hidden; }

.content_broadcast {
	width: 830px;
	float: left; }

.post_message_now {
	margin-bottom: 25px!important; }

.post_message_now,
.post_add_trigger,
.post_schedule_later,
.post_sourse {
	margin: 0 0 25px;
	span {
		display: block;
		padding: 3px 0; } }

.post_title {
	font-size: $fzContent;
	line-height: 26px;
	font-family: 'CenturyGothic-Bold';
	font-size: 13px;
	color: #666;
	margin: 0 0 6px;
	cursor: default;
	font-weight: 500; }

.btn_message_now {
	width: 100%;
	border-radius: 5px;
	background-color: #eee;
	padding: 8px 20px;
	border: 1px solid #ddd; }

.tags {
	width: 100%;
	display: flex;
	flex-wrap: wrap; }

.tags-cell {
	width: 46.99%;
	margin: 2px;
	border: 1px solid #d0d0d0;
	border-radius: 2px;
	transition: .4s all linear;
	padding: 4px 3px;
	text-align: center;
	cursor: pointer;
	display: flex;
	justify-content: center;
	position: relative;

	&:hover {
		box-shadow: 0 2px 8px -2px rgba(0,0,0,.13); }
	span {
		font-size: 12px; }
	.tags_off {
		position: absolute;
		top: 5px;
		right: 10px;
		width: 5px;
		height: 5px;
		background-color: #45c195;
		border-radius: 5px; } }

.post_add_button {
	display: block!important;
	vertical-align: top;
	width: 100%;
	line-height: 34px;
	cursor: pointer;
	text-align: center;
	font-size: 12px;
	min-height: 28px;
	margin: 0;
	padding: 10px 3px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	position: relative;
	transition: .3s all linear;

	&:before {
		content: " ";
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		width: 100%;
		height: 100%;
		background: url(/img/add.svg) no-repeat center center;
		background-size: 15px 15px;
		opacity: .8; } }

//    on-off

.switch {
	width: 50px;
	height: 26px;
	border-radius: 13px;
	cursor: pointer;
	display: inline-block;
	background-color: #dcdee2; }

.switch-toggle {
	width: 20px;
	height: 20px;
	left: 4px;
	top: 3px;
	border-radius: 12px;
	background-color: #fff;
	position: relative;
	transition: left .2s ease-in-out; }

.switch.on {
	background: #5353FF; }


.switch.on .switch-toggle {
	left: 27px;
	top: 3px; }

.switch.disabled {
	cursor: not-allowed; }

//  main panel
.broad_main {
	position: relative;
	height: calc(100vh - 60px);
	overflow-y: auto;
	padding: 0; }

.broad_main_content_block {
	background-color: #FFF;
	height: 100%; }

.trigger {
	display: flex;
	width: 100%;
	align-items: center;

	.switch_select {
		width: 10%; }

	span {
		width: 30%; }

	.dublicate {
		width: 15%; } }

.block_duplicate {
	display: inline-block;
	width: 30px;
	height: 30px;
	background: url(/img/copy.svg) no-repeat center center;
	background-size: 30px 30px;
	cursor: pointer;
	opacity: 0.3; }

.deliver_txt {
	padding: 10px 0;
	margin: 25px 0 0 0; }

// settings trigger
.trigger_settings {
	padding: 15px 0; }

.settings_trigger,
.settings_time {
	margin: 15px 0;
	display: flex;
	flex-wrap: wrap;

	.left {
		width: 20%; }

	.right {
		width: 80%; } }

// content box popup trigger
.wrap_form_top {
	padding: 15px 0;
	border-bottom: 1px solid #ccc;
	input {
		transition: background-color .1s,border .1s,background-color .1s;
		outline: none;
		padding: 7px 15px; } }

.react-tabs {
	padding: 15px 0; }

.content_box {
	top: 190px;
	width: 402px;
	max-height: 1400px;
	box-shadow: 0 8px 16px 0 rgba(0,0,0,.13);
	border: 1px solid rgba(0,0,0,.09);
	z-index: 100000;
	transition: width .1s cubic-bezier(.2,.7,.5,1),max-height .13s cubic-bezier(.2,.7,.5,1),box-shadow .1s cubic-bezier(.2,.7,.5,1),border-color .1s cubic-bezier(.2,.7,.5,1);
	left: 50%;
	position: absolute;
	transform: translateX(-50%);
	background-color: #FFF;
	padding: 10px 15px; }

// wrap card text
.wrap_card_text {
	padding: 15px 20px;
	width: 255px;
	position: relative;
	background-color: #FFF;
	border: 1px solid #ccc;
	border-radius: 7px;
	box-shadow: 0 0 0 1px rgba(0,0,0,.04), 0 1px 0 0 rgba(0,0,0,.08);
	textarea {
		width: 100%;
		resize: none; } }
.wrap_card_text_add_button {
	font-size: 14px;
	color: #000;
	text-align: center;
	line-height: 48px;
	height: 49px;
	cursor: pointer;
	text-transform: uppercase;
	position: relative;
	width: 100%;
	border-top: 1px solid #ebebeb;
	border-color: #fff;
	transition:  .4s ease;

	&::before {
		content: " ";
		width: 24px;
		height: 24px;
		display: inline-block;
		background: url(/img/add.svg) no-repeat center center;
		background-size: 24px 24px;
		opacity: .25;
		vertical-align: -7px;
		transition: opacity 60ms; } }

// add to card
.list_menu_navigator {
	display: flex;
	width: 100%;
	padding: 0;
	margin: 0;
	li {
		width: 14.285%;
		display: inline-block;
		cursor: pointer;
		border: 1px solid #ccc;
		padding: 5px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		&:hover {
			.icon {
				opacity: 0.8; } }

		span {
			display: block; } } }

.icon {
	display: inline-block;
	width: 100%;
	height: 30px;
	background: #ccc;
	background-size: 30px 30px;
	opacity: 0.4; }
.tx {
	background: url(/img/ic_textcard.svg) no-repeat center center; }
.tp {
	background: url(/img/ic_typing-sm.svg) no-repeat center center; }
.qu {
	background: url(/img/ic_qr_plugin.svg) no-repeat center center; }
.im {
	background: url(/img/ic_imagecard.svg) no-repeat center center; }
.gl {
	background: url(/img/ic_gallery-sm.svg) no-repeat center center; }
.sq {
	background: url(/img/ic_to-sequence.svg) no-repeat center center; }

.overlay_main {
	padding: 10px 15px;
	background: #EFFDFF; }

.filterPopup {
	width: 100%;
	height: 100vh;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 999999;
	background-color: rgba(0,0,0, 0.5); }

.popup_filter_content {
	position: relative;
	min-height: 405px;
	transition: opacity .3s ease-out;
	background: #fff none repeat scroll 0 0;
	padding: 26px 25px;
	margin: 0 auto;
	margin-top: calc((100% /2) - 35%);
	border: none;
	max-width: 790px;
	position: relative;
	width: auto;
	transition: width 0ms 0ms,height 0ms 0ms,opacity .2s 0ms;
	background-color: rgb(255, 255, 255);
	box-shadow: rgba(0, 0, 0, 0.16) 0px 16px 48px 0px, rgba(0, 0, 0, 0.12) 0px 0px 0px 1px;
	transition: transform 0.1s 0ms;
	overflow: hidden; }

.top_popup_filter {
	padding: 10px 15px;
	background: #b1b1b1;
	p {
		position: relative;
		padding: 0 0 0 32px;
		font-size: 20px;
		margin: 0 0 16px;
		font-size: $fzContent;

  span {} }
	display: block; }

.filter_list_inp {
	display: flex;
	span {
		width: 15%;
		padding: 10px 15px;
		border: 1px solid #ccc;
		margin-right: 5px; }
	input,
	select {
		width: 25%;
		margin-right: 5px;
		padding: 10px 15px;
		border: 1px solid #ccc; } }

.filter_popup_bottom {
	border-top: 1px solid #ccc;
	display: flex;
	align-items: center;
	padding: 5px 0;
	align-items: baseline;
	.bottom_left {
		width: 50%; }
	.bottom_right {
		width: 50%;
		display: flex;
		justify-content: flex-end; } }


.popup_filter {
	border: 1px solid #eaeaea;
	padding: 15px 10px;
	background: rgba(0,0,0,.5); }

.btn_filter_delete {
	padding: 17px 28px;
	position: relative;
	background: none;
	border: 1px solid rgba(0,0,0,.46);
	&:before {
		content: '';
		top: 50%;
		left: 50%;
		width: 18px;
		height: 18px;
		transform: translate(-50%,-50%);
		background: url(/img/delete.svg) no-repeat center center;
		background-size: 18px 18px;
		position: absolute; } }


.add_filter_group_list {
	width: 100%;
	margin-top: 5px;
	display: flex;
	.filter_width {
		width: 90%; }
	.filter_width_2 {
		width: 100%;
		display: flex; }
	.filter_score {
		width: 25%;
		margin-right: 2px; } }

.add_filter_group,
.filter_group {
	width: 90%;
	.filter_score {
		width: 25%; } }
.filter_score {
	display: inline-block;
	vertical-align: top;
	line-height: 34px;
	text-align: center!important;
	font-size: 15px;
	color: #333;
	// margin: 0 8px 0 0
	height: 36px;
	opacity: 1;
	background-color: #fff;
	border: 1px solid #dfdfdf;
	.select_w {
		width: 100%;
		height: 100%;
		border: none; } }

.attr_input {
	display: inline-block;
	vertical-align: top;
	line-height: 34px;
	text-align: left!important;
	font-size: 15px;
	color: #333;
	margin: 0 8px 0 0;
	width: 100%;
	height: 36px;
	padding: 0 10px;
	opacity: .8;
	background-color: #fff;
	border: 1px solid #dfdfdf;
	&:focus {
		outline: none; } }

.filter_is {
	position: relative;
	display: inline-block;
	height: 36px;
	width: 84px;
	text-align: left;
	border: 1px solid rgba(0,0,0,.46); }


.popup_filter_bottom {
	display: flex;
	.bottom_left {
		width: 50%;
		span {
			b {
				font-family: 'Hind Guntur', sans-serif;
				font-size: 16px;
				margin: 0 2px;
				color: #777;
				font-weight: normal; } } }
	.bottom_right {
		width: 50%;
		display: flex;
		justify-content: flex-end; } }


.user_filter_send {
	display: flex;
	justify-content: flex-end;
	width: 90%; }


.message_block_center {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	//background-color: hsla(0,0%,88%,.3)
	padding: 10px 15px;
	h2 {
		font-size: 17px;
		font-family: 'CenturyGothic-Bold';
		color: #333; }
	.text_center_block {
		padding: 25px 15px 15px 120px;
		position: relative;
		line-height: 1.6;
		max-width: 650px;
		margin: auto;
		background-color: hsla(0,0%,88%,.3);
		&:before {
			content: '';
			position: absolute;
			top: 55px;
			left: 7px;
			width: 110px;
			height: 110px;
			background: url(/img/gramophone.svg) no-repeat center center;
			background-size:  110px 110px; }
		p {
			font-size: 14px; } }
	.text_center_block_success {
		padding: 25px 25px 25px 120px;
		position: relative;
		line-height: 1.6;
		max-width: 650px;
		margin: auto;
		background-color: hsla(0,0%,88%,.3);
		&:before {
			content: '';
			position: absolute;
			top: 51px;
			left: 20px;
			width: 80px;
			height: 80px;
			background: url(/img/check.svg) no-repeat center center;
			background-size:  80px 80px; } } }

// .container2 .EditMessage
// 	border-color: #c3c3c3
// 	.TypingTime
// 		border-color: #c3c3c3

// .container2 .EditMessage textarea
// 	border-color: #c3c3c3



.list_schedule_later {
	position: relative; }

.shedule_overlay_settings {
	width: 100%;
	display: flex;
	.schedule_input_1,
	.schedule_input_2 {
		width: 25%;
		padding: 0 3px;
		select,
		input {
			padding: 10px 0;
			width: 100%; } } }


.block_list_schedule_custom {
	padding: 10px 0; }

.list_shedule {
	display: flex;
	list-style-type: none;
	li {
		width: 14.285%;
		background-color: #90b4f6;
		border-left: 1px solid #769bdf;
		padding: 10px 12px;
		cursor: pointer;
		transition: all .4s;
		color: white;
		&:hover {
			background-color: #769bdf; }
		span {
			display: block;
			text-align: center; } } }

.popup_filter {
	width: 100%;
	height: 100%;
	overflow: auto;
	position: absolute;
	z-index: 10;
	min-height: unset!important;
	background: rgba(0,0,0,.5); }

.filter_inner {
	width: 690px;
	overflow: visible;
	padding: 32px 32px 92px;
	top: 40%;
	transform: translate(-50%,-50%) scale(1);
	-webkit-transition: transform .1s 0ms;
	left: 50%;
	z-index: 1;
	border-radius: 7px;
	border-color: #fff;
	transition: width 0ms 0ms,height 0ms 0ms,opacity .2s 0ms;
	box-shadow: 0 16px 48px 0 rgba(0,0,0,.16), 0 0 0 1px rgba(0,0,0,.12);
	position: relative;
	background-color: #FFF; }


.oSave {
	padding: 5px 15px 15px 25px;
	color: green;
	display: inline-block;
	font-size: 14px;
	position: relative;
	&:before {
		content: '';
		position: absolute;
		width: 17px;
		height: 17px;
		top: 5px;
		left: 0;
		background: url(/img/check.svg) no-repeat center center;
		background-size: 17px 17px; } }
.nSave {
	padding: 5px 15px 15px 0;
	color: #555;
	display: inline-block;
	font-size: 14px; }


.filter_title {
	font-size: 1.7rem;
	margin: 0 0 1px 0;
	width: 100%;
	p {
		padding: 0 25px;
		font-size: 20px;
		color: #767676;
		font-family: 'CenturyGothic-Bold';
		font-weight: 500;
		position: relative;
		&:before {
			content: '';
			position: absolute;
			top: 5px;
			left: 0;
			width: 22px;
			height: 22px;
			background: url(/img/user2.svg) no-repeat center center;
			background-size: 22px 22px; } } }

//  react data picker
.react-date-picker__button {
	min-width: 25%!important;
	border: 1px solid #d2dae3!important;
	padding: 1px 0; }

.react-date-picker__calendar--above-label {
	top: 112%!important;
	z-index: 999; }

.react-date-picker__calendar {
	min-width: 100%!important;
	width: 300px!important;
	max-width: none!important; }
.filter_delete {
	padding: 30px 25px;
	margin: 0 auto;
	// margin-top: calc((100% /2) - 35%)
	border: none;
	min-width: 450px;
	// position: relative
	text-align: center;
	width: auto;
	background: #fff;
	transition: opacity .3s linear;
	position: absolute;
	transform: translate(-50%,-50%);
	left: 50%;
	top: 50%;
	h1 {
		line-height: 1.7;
		margin: 0;
		padding: 15px 0;
		font-size: 20px;
		color: #333; }

	.redButton {
		margin: 0 5px;
		padding: 10px 35px;
		border: none;
		border-radius: 3px;
		transition: all .4s;
		background: #6c7588;
		color: #fff; }
	.button {
		margin: 0 5px;
		padding: 10px 35px;
		border: none;
		border-radius: 3px;
		transition: all .4s;
		background: #6c7588;
		cursor: pointer;
		color: #fff; } }

.react-date-picker__button__input {
	padding: 0 5px!important; }

.btn_filter_w {
	//display: flex
	//justify-content: flex-end
 }	//width: 100%
//.Select-placeholder, .Select--single > .Select-control .Select-value
//	color: #000!important
//	//border: 1px solid #d2dae3!important
.react-date-picker {
	display: grid!important; }


.broadcast_contest {
	margin-top: 10px; }

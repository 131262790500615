.AdminContent {
	margin-left: 193px;
	height: 100vh;
	background: #fff;
	position: relative; }

.Admin_main--content {
	background: #fff;
	z-index: 0;
	height: 100%;
	position: relative;
	height: calc(100vh - 90px);
	overflow-y: auto {
    // padding: 0
 } }    // padding: 0 0 0 205px;

.admin_left_panel {
	background: #effdff;
	height: 100vh;
	min-width: 255px;
	max-width: 255px;
	text-align: center;
	z-index: 1;
	padding: 0 5px;
	float: left; }


.admin_menu {
	text-align: center;
	margin-top: 20px;

	li {
		padding: 10px 15px;
		a {
			color: #333; } }
	span {
		display: block; } }

.admin_logo_top {
	// background: #78a5fe
	color: #000;
	float: left;
	width: 193px; }

.admin_top {
	position: relative;
	z-index: 2;
	font-size: 17px;
	color: #f1f1f1;
	width: 100%;
	height: 59px;
	padding: 0;
	letter-spacing: 1px;
	background: #eee;
	a {
		color: #333;
		width: 100%;
		text-align: center;
		display: inline-block;
		height: 59px;
		padding: 0 15px;
		transition: .4s all;
		line-height: 70px;
		background: url(/img/logo_login.png) no-repeat center center; } }

.chart_block {
	width: 100%;
	margin-bottom: 30px; }

.settings_bot_wrap {
	display: flex;
	justify-content: space-around;
	max-width: 650px;
	overflow: hidden;

	.upload_settings {
		width: 65%; }
	.upload_text {
		width: 65%;
		textarea {
			width: 100%;
			height: 100%;
			padding: 5px 15px; } } }

.settings_bot_list {
	padding: 20px 0;
	margin-top: 10px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	height: 495px; }
.admin_bot {
	width: 48%;
	margin-bottom: 10px;
	display: flex;
	border: 1px solid #dedede;
	display: inline-block;
	margin: 6px 3px;
	padding: 10px 0; }

.admin_bot_left {
	width: 40%;
	height: 100%;
	display: inline-block; }
.admin_bot_images {
	width: 100%;
	padding: 10px 10px;
	display: flex;
	img {
		width: 100%;
		height: 100%;
		margin:auto {} } }

.admin_bot_right {
	width: 60%;
	padding: 0 10px;
	display: inline-block; }

.admin_bot_title {
	margin-top: 20px;
	padding: 5px 0;
	display: flex;
	width: 100%;

	span {
		width: 70%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap; }

	.edit {
		width: 20px;
		height: 20px;
		display: inline-block;
		background: url(/img/edit1.svg) no-repeat center center;
		background-size: 18px 18px;
		opacity: .7;
		border: none; }

	.delete {
		width: 20px;
		height: 20px;
		display: inline-block;
		background: url(/img/delete.svg) no-repeat center center;
		background-size: 18px 18px;
		border:  none; } }

.admin_bot_title_icon {
	width: 30%; }

.admin_bot_text {
	width: 100%;
	height: 100%;
	textarea {
		width: 100%;
		height: 80%;
		border: 1px solid #dedede; } }

.bot_right_content {
	width: 100%; }

.fileContainer .uploadPictureContainer {
	width: 100%!important;
	height: 130px!important; }

.fileContainer .uploadPictureContainer img.uploadPicture {
	width: 65%!important; }

.fileUploader {
	padding: 0 10px; }

.upload_settings .ReactGridGallery {
	height: 100%;
	border: none; }

.admin_bot_bottom {
	display: flex; }

.admin_bot_bottom_btn {
	width: 50%; }

.admin_bot_title_icon {
	width: 50%; }

.tile {
	margin: 0!important; }



//  column tariffs
.column_tariff {
	display: flex;
	width: 100%; }


// admin left menu
.pa_admin {
	padding: 1.5rem; }

.admin_top_navigator {
	span {
		color: #666; }
	li {
		padding: 0.6em 0.4rem;
		text-align: left;

		a {
			padding: 0 0.3rem;
			line-height: 1.75rem;
			display: block;
			position: relative;
			font-size: 13px; } } }


.nav__admin--icon {
	float: left;
	line-height: inherit;
	margin-right: 0.3rem;
	top: 0;
	min-width: 1.1rem;
	i {
		font-size: 13px; } }

.nav__admin--text {
	line-height: 1.125rem;
	padding: .5625rem 0; }


.template__gallery__admin {
	display: flex;
	.ReactGridGallery {
		height: auto;
		border: none;
		width: 100%; } }
